import React, { useContext } from "react";
import { TopicContext } from "./TopicContext";
import "./nav-bar.css";

export const NavBar = () => {
  // eslint-disable-next-line
  const {
    // eslint-disable-next-line
    topicType,
    setTopicType,
    // eslint-disable-next-line
    itemChosen,
    setItemChosen,
    // eslint-disable-next-line
    carouselIndex,
    setCarouselIndex,
  } = useContext(TopicContext);

  const handleClick = (e) => {
    setTopicType((topic) => e.target.id);
    setItemChosen((topic) => false);
    setTimeout(() => {
      setItemChosen((topic) => true);
    }, 3000);
    setCarouselIndex((topic) => 3);
  };
  const dropdownClick = (e) => {
    e.target.classList.toggle("open");
  };
  return (
    <header className="app-header">
      <div className="nav-link-container">
        <div
          onClick={handleClick}
          id="history"
          className="navigation-link selected"
        >
          Neutralizing Allergens
          <div className="lozenge"></div>
        </div>
        <div className="navigation-link empty"></div>
        <div className="navigation-link empty"></div>
        <div className="navigation-link empty"></div>
        <div className="navigation-link empty"></div>
        <div className="dropdown" onClick={dropdownClick}>
          <img
            src="https://storage.googleapis.com/purina-virtual-experience/design/ui/Selected%20Info%20Icon.png"
            alt="Learn More"
          />
          <div id="learn-more" className="dropdown-content">
            <a
              href="https://www.purinainstitute.com/science-of-nutrition/neutralizing-allergens"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>Learn More</p>
            </a>

            <div id="references">
              <a
                href="https://www.purinainstitute.com/science-of-nutrition/neutralizing-allergens/references"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>References</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </header>
  );
};
