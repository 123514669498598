import React, { useState, useEffect, useContext } from "react";
import { TopicContext } from "./TopicContext";
import "./Carousel.css";
import http from "../lib/http";
import Slide from "./Slide";

export default function Carousel() {
  const [slide, setSlide] = useState([
    {
      index: 1,
      info1: "",
      info2: "",
      info3: "",
      footnote: "",
      imageURL: "",
      alt: "",
    },
  ]);
  // const [introText, setIntroText] = useState("");
  useEffect(() => {
    async function fetchData() {
      const { data } = await http.get(`/api/slides/`);
      setSlide(data.data.slides);
    }
    fetchData();
    return () => {};
  }, [slide]);

  const {
    carouselIndex, // eslint-disable-next-line
    setCarouselIndex,
  } = useContext(TopicContext);
  const rightArrowClick = () => setCarouselIndex((topic) => carouselIndex + 1);
  const leftArrowClick = () => setCarouselIndex((topic) => carouselIndex - 1);

  return (
    <div className="carousel">
      <div className="carousel-container">
        <button
          onClick={leftArrowClick}
          className={`${carouselIndex === 1 ? "hide" : ""} arrow left`}
        >
          <img
            alt="left arrow"
            src="https://storage.googleapis.com/purina-virtual-experience/design/ui/Left%20Arrow.png"
          />
        </button>
        <button
          onClick={rightArrowClick}
          className={`${carouselIndex === 4 ? "hide" : ""} arrow right`}
        >
          <img
            alt="right arrow"
            src="https://storage.googleapis.com/purina-virtual-experience/design/ui/Right%20Arrow.png"
          />
        </button>
        <div
          className="inner"
          style={{
            transform: `translateX(${-(carouselIndex * 100) + 100}%)`,
          }}
        >
          <Slide {...slide[0]} />
          <Slide {...slide[1]} />
          <Slide {...slide[2]} />
          <Slide {...slide[3]} />
        </div>
      </div>
    </div>
  );
}
